// Libs
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Style
import './Experience.scss';

export default function Experience(props) {
    const { t } = useTranslation();
    const [selectedExperience, setSelectedExperience] = useState(null);
    const [sortedExperience, setSortedExperience] = useState([]);

    useEffect(() => {
        // Trier les expériences par startDate décroissante
        const sorted = [...props.experience].sort((a, b) => {
            const dateA = new Date(a.startDate); // Conversion en objet Date
            const dateB = new Date(b.startDate);
            return dateB - dateA; // Tri décroissant
        });
        setSortedExperience(sorted);

        // Sélectionner la première expérience si la liste est non vide
        if (sorted.length > 0) {
            setSelectedExperience(0);
        }
    }, [props.experience]);

    function handleClick(index) {
        setSelectedExperience(index);
    }

    return (
        <section className="experience" id="section-experience">
            <h2>
                {t('experiences.MainTitle')}{' '}
                <span className="color-tertiary">{t('experiences.TitleSpan')}</span>
            </h2>
            <div className="experience-container">
                <div className="experience-content">
                    <ul className="experience-list">
                        {sortedExperience.map((experience, index) => {
                            const isActive = index === selectedExperience;
                            const itemClasses = isActive ? 'experience-list-item active' : 'experience-list-item';
                            const slug = experience.slug;

                            return (
                                <li
                                    key={index}
                                    className={itemClasses}
                                    onClick={() => handleClick(index)}
                                >
                                    <div className="experience-list-item-logo">
                                        <img src={experience.logo} alt={experience.title} />
                                    </div>
                                    <div className="experience-list-item-content">
                                        <p className="experience-list-item-title">{experience.title}</p>
                                        <span className="experience-list-item-date light-text">
                      {t(`experiences.${slug}.Date`)}
                    </span>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    {selectedExperience !== null && (
                        <div className="experience-detail">
                            <div className="experience-detail-header">
                                <p className="experience-detail-title">
                                    {t(`experiences.${sortedExperience[selectedExperience].slug}.Title`)}{' '}
                                    <a
                                        className="underline-anim color-tertiary"
                                        href={sortedExperience[selectedExperience].details.company.link}
                                        title={sortedExperience[selectedExperience].details.company.name}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        @{sortedExperience[selectedExperience].details.company.name}
                                    </a>
                                </p>
                                <span className="experience-detail-date light-text">
                  {t(`experiences.${sortedExperience[selectedExperience].slug}.Date`)}
                </span>
                            </div>
                            <div className="experience-detail-content">
                                <ul className="experience-detail-content-list">
                                    {sortedExperience[selectedExperience].details.tasks.map((task, index) => (
                                        <li key={index} className="experience-detail-content-list-item">
                                            <i className="icon-angle-right color-tertiary"></i>
                                            <span>
                        {t(`experiences.${sortedExperience[selectedExperience].slug}.Task${index + 1}`)}
                      </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}